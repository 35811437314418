/* global axios */
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import '../styles/form.scss';

/**
 * Functional component to handle the survey results
 * @param {Array} answers The given answers
 * @return {Component} React component
 */
function Form({answers}) {
    const [name, setName] = useState('');
    const [company, setCompany] = useState('');
    const [mail, setMail] = useState('');
    const [phone, setPhone] = useState('');
    const [consent, setConsent] = useState(false);
    const [validationFails, setValidationFails] = useState({});
    const [mailStatus, setMailStatus] = useState('waiting');

    /**
     * Sends an ajax request to deliver the mails
     */
    function sendMail() {
        if (consent) {
            setMailStatus('pending');
            axios.post('/sendMail', {
                name: name,
                company: company,
                mail: mail,
                phone: phone,
                answers: answers,
            }).then((success) => {
                setMailStatus('submitted');
            }).catch((error) => {
                setValidationFails(error.response.data.errors);
                setMailStatus('error');
            });
        }
    }

    /**
     * @return {String} The Mail status
     */
    function displayMailStatus() {
        switch (mailStatus) {
        case 'pending':
            return 'Der Mail Versandt wird vorbereitet.';
        case 'submitted':
            return 'Die Mail wurde versendet.';
        case 'error':
            return 'Es gab einen Fehler beim versenden der Mail.';
        }
    }

    /**
     * Returns the error message
     * @param {String} name The input name
     * @return {String} The error message
     */
    function showErrorMsg(name) {
        if (typeof validationFails[name] !== 'undefined') {
            return validationFails[name][0];
        }
        return '';
    }

    return (
        <>
            <div className="row">
                <div className="form-group col-6">
                    <input
                        className={
                            `go-input${(validationFails.name) ? ' error' : ''}`
                        }
                        type="text"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Vor-/Nachname*"
                    />
                    <span className="error text">{showErrorMsg('name')}</span>
                </div>
                <div className="form-group col-6">
                    <input
                        className={
                            `go-input${
                                (validationFails.company) ? ' error' : ''
                            }`
                        }
                        type="text"
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Unternehmen*"
                    />
                    <span
                        className="error text"
                    >{showErrorMsg('company')}</span>
                </div>
            </div>
            <div className="row">
                <div className="form-group col-6">
                    <input
                        className={
                            `go-input${(validationFails.mail) ? ' error' : ''}`
                        }
                        type="text"
                        onChange={(e) => setMail(e.target.value)}
                        placeholder="E-Mail*"
                    />
                    <span className="error text">{showErrorMsg('mail')}</span>
                </div>
                <div className="form-group col-6">
                    <input
                        className={
                            `go-input${(validationFails.phone) ? ' error' : ''}`
                        }
                        type="text"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Telefonnummer"
                    />
                    <span className="error text">{showErrorMsg('phone')}</span>
                </div>
            </div>
            <div>
                <span>Die mit * markierten Felder sind Pflichtfelder</span>
            </div>
            <div className="row">
                <div className="form-group col-12">
                    <ul className="checkBoxList">
                        <li className="checkBoxList text-center go-container">
                            <input
                                id={'consent'}
                                type="checkbox"
                                name={'consent'}
                                onChange={() => setConsent(!consent)}
                                className="quizQuestion btn-block mt-1"
                            />
                            <label
                                htmlFor={'consent'}
                                className="checkboxlabel ml-0 mr-2"
                            >
                            </label>
                            {/* eslint-disable-next-line max-len */}
                            <label htmlFor={'consent'} className="checkboxText fs-15">Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage gelöscht. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an <a href="mailto:info@1a-digital.com">info@1a-digital.com</a> widerrufen. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unserer Datenschutzerklärung.</label>
                        </li>
                    </ul>
                    <label
                        htmlFor={'consent'}
                        className="description-text text-left pt-3"
                    />
                </div>
            </div>
            <div className="row justify-content-center">
                <button
                    className={
                        `go-button animated${(!consent) ? ' disabled' : ''}`
                    }
                    type="button"
                    onClick={() => sendMail()}
                    disabled={
                        mailStatus == 'pending' || mailStatus == 'submitted'
                    }
                >Absenden</button>
            </div>
            <div className="row justify-content-center mt-3">
                <span>{displayMailStatus()}</span>
            </div>
        </>
    );
}

Form.propTypes = {
    answers: PropTypes.array,
};

export default Form;
