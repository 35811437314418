import React, {useState} from 'react';
import Headline from './components/Headline';
import Question from './components/Question';
import Result from './components/Result';
import CookieAndAnalytics from './components/CookieAndAnalytics';

import './styles/home.scss';

/**
 * Functional component which contains the go digital check
 * @return {Component} React component
 */
function Home() {
    const [answers, setAnswers] = useState([]);

    /**
     * Set the answer at the given index
     * @param {Boolean} answer The answer
     * @param {Number} index The answers index
     */
    function setAnswer(answer, index) {
        const isNowBool = answer === 'true';

        if (!answers.length) {
            setAnswers([isNowBool]);
        } else {
            const copy = [...answers];
            copy.splice(index, 1, isNowBool);

            setAnswers(copy);
        }
    }

    /**
     * Contains logic to show the Result
     * @return {Boolean}
     */
    function showResult() {
        return answers.length === 3;
    }

    return (
        <div className="container-fluid">
            <h1
                id="mainHeading"
                className="mainHeading"
            >
                Entdecken Sie Ihr Digitalisierungspotential!
            </h1>

            <div className="row content pb-5 justify-content-center">
                <div className="mainContent">
                    <Headline>Ihre Förderfähigkeit</Headline>
                    {/* eslint-disable-next-line max-len */}
                    <p className="text-center fs-22">Sie wollen mit der go-digital Förderung des BMWI bei der Umsetzung Ihres Digitalisierungsprojektes bis zu 16.500 EUR Fördersumme erhalten? Beantworten Sie kurz 3 Fragen und kommen Sie der Förderung einen Schritt näher!</p>

                    <Question
                        // eslint-disable-next-line max-len
                        headline={'Beschäftigen Sie weniger als 100 Mitarbeitende in Ihrem Unternehmen?'}
                        answer={answers[0]}
                        setAnswer={(answer) => setAnswer(answer, 0)}
                        show={answers.length >= 0}
                    />

                    <Question
                        // eslint-disable-next-line max-len
                        headline={'Haben Sie innerhalb der letzten drei Jahre weniger als 200.000€ staatliche Förderung erhalten?'}
                        answer={answers[1]}
                        setAnswer={(answer) => setAnswer(answer, 1)}
                        show={answers.length >= 1}
                    />

                    <Question
                        // eslint-disable-next-line max-len
                        headline={'Erwirtschaftet Ihr Unternehmen jährlich weniger als 20 Mio. € Umsatz bzw. weisen Sie weniger als 20 Mio € Bilanzsumme aus?'}
                        answer={answers[2]}
                        setAnswer={(answer) => setAnswer(answer, 2)}
                        show={answers.length >= 2}
                    />

                    <Question
                        // eslint-disable-next-line max-len
                        headline={'Ist Ihr Betrieb ein Unternehmen der gewerblichen Wirtschaft?'}
                        answer={answers[1]}
                        setAnswer={(answer) => setAnswer(answer, 3)}
                        show={answers.length >= 3}
                        force={'hide'}
                    />

                    <Result
                        type={answers.every((x) => x === true)}
                        show={showResult()}
                        answers={answers}
                    />
                </div>
            </div>

            <CookieAndAnalytics />
        </div>
    );
}

export default Home;
