import React, {useState, useEffect} from 'react';
import CookieConsent from 'react-cookie-consent';

/**
 * Functional component to add a cookie notice and google analytics
 * @return {Component} React component
 */
function CookieAndAnalytics() {
    const [status, setStatus] = useState(getCookie('CookieConsent') === 'true');
    const [blockContent, setBlockContent] = useState(
        getCookie('CookieConsent') === undefined,
    );

    useEffect(() => {
        if (status) {
            loadScript(() => {
                window.dataLayer = window.dataLayer || [];

                /**
                 * Googles gtag function
                 */
                function gtag() {
                    // eslint-disable-next-line prefer-rest-params, no-undef
                    dataLayer.push(arguments);
                }

                gtag('js', new Date());
                gtag('config', 'UA-108369194-6');
                // eslint-disable-next-line no-undef
                ga('set', 'anonymizeIp', true);
            });
        }
    }, [status]);

    /**
     * Load the google tag manager script
     * @param {Function} onLoad The function called on load
     */
    function loadScript(onLoad) {
        const script = document.createElement('script');
        script.src = 'https://www.googletagmanager.com/gtag/js?id=UA-108369194-6';
        script.async = true;
        script.onload = () => onLoad();
        document.head.appendChild(script);
    }

    /**
     * Getter for cookies
     * @param {*} cookieName The name of the cookie to extract
     * @return {any} The expected value or undefined
     */
    function getCookie(cookieName) {
        const name = cookieName + '=';
        const decodedCookie = decodeURIComponent(document.cookie);

        return decodedCookie.split(';').map((cookie) => {
            while (cookie.charAt(0) === ' ') {
                cookie = cookie.substring(1);
            }
            if (cookie.indexOf(name) === 0) {
                return cookie.substring(name.length, cookie.length);
            }
        }).filter((x) => x !== undefined)[0];
    }

    return (
        <div className={(blockContent) ? 'go-digital-banner-background' : ''}>
            <CookieConsent
                enableDeclineButton
                location="top"
                declineButtonText="Alle Cookies zulassen"
                buttonText="Notwendige Cookies zulassen"
                cookieValue="false"
                declineCookieValue="true"
                onAccept={() => {
                    setStatus(false);
                    setBlockContent(false);
                }}
                onDecline={() => {
                    setStatus(true);
                    setBlockContent(false);
                }}
                containerClasses="go-digital-banner"
                style={{
                    background: 'white',
                    color: '#606569',
                }}
                buttonClasses="go-digital-banner-decline"
                declineButtonClasses="go-digital-banner-btn accept"
            >
                <h3>COOKIE-EINSTELLUNG</h3>
                <p className="mb-0">Bitte treffen Sie eine Auswahl.</p>
                <p className="mb-0">
                    {/* eslint-disable-next-line max-len */}
                    Weitere Informationen unter: <a href="/datenschutz" target="_blank">Infos zum Datenschutz</a>.
                </p>
            </CookieConsent>
        </div>
    );
}

export default CookieAndAnalytics;
