import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Headline from './Headline';
import {v4} from 'uuid';

import '../styles/question.scss';

/**
 * Functional component to display a yes or no question
 * @prop {String} headline The headline
 * @prop {Boolean} answer The answer
 * @prop {Function} setAnswer Pass answer to parent component
 * @prop {Boolean} show If true displays the component
 * @prop {String} force Contains 'show', 'hide' or nothing
 * @return {Component} React component
 */
function Question({headline, answer, setAnswer, show, force}) {
    const ids = [v4(), v4()];
    const uniqueName = useState(v4());

    /**
     * Determines if the question should be displayed
     * @return {Boolean}
     */
    function shouldShow() {
        if (force === 'show') {
            return true;
        } else if (force === 'hide') {
            return false;
        } else {
            return show;
        }
    }

    /**
     * Build the wrappers className
     * @return {String}
     */
    function className() {
        let name = 'mb-3';

        if (answer === true) {
            name += ' check-background';
        } else if (answer === false) {
            name += ' cross-background';
        }

        return name;
    }

    return (shouldShow()) ? (
        <div className={className()} >
            <Headline><>{headline}</></Headline>

            <div className="row">
                {['Ja', 'Nein'].map((label, index) =>
                    <div className="col-md-6" key={index}>
                        <ul className="checkBoxList">
                            <li className="checkBoxList text-center">
                                <input
                                    id={ids[index]}
                                    type="radio"
                                    name={uniqueName}
                                    value={!index}
                                    onClick={(e) => setAnswer(e.target.value)}
                                    className="quizQuestion btn-block mt-1"
                                />
                                <label
                                    htmlFor={ids[index]}
                                    className="checkboxlabel ml-2"
                                >
                                    <p className="checkboxText">{label}</p>
                                </label>
                            </li>
                        </ul>
                        <label
                            htmlFor={ids[index]}
                            className="description-text text-left pt-3"
                        />
                    </div>,
                )}
            </div>
        </div>
    ) : '';
}

Question.propTypes = {
    headline: PropTypes.string,
    answer: PropTypes.bool,
    setAnswer: PropTypes.func,
    show: PropTypes.bool,
    force: PropTypes.string,
};

export default Question;
