/**
 * Wrapper for react main components
 */
import React from 'react';
import ReactDOM from 'react-dom';
import Home from './Home/Home';

const node = document.getElementById('app-content');

if (node) {
    const type = node.getAttribute('data-type');

    switch (type) {
    case 'home':
        ReactDOM.render(<Home />, node);
        break;
    }
}
