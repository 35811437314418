import React from 'react';
import ProtTypes from 'prop-types';
import Headline from './Headline';
import Form from './Form';
import '../styles/result.scss';

/**
 * Functional component to display the survey result
 * @param {Boolean} type Determines the type of the result
 * @param {Boolean} show Determines if the result should be shown
 * @return {Component} React component
 */
function Result({type, show, answers}) {
    return (show) ? (
        <>
            <Headline>
                {
                    (type) ?
                        <>
                            Sie sind <span className="check">förderfähig</span>.
                        </> :
                        <>
                            Sie sind leider <span
                                className="cross"
                            >nicht förderfähig</span>.
                        </>
                }
            </Headline>

            <p className="text-center fs-22">
                {
                    (type) ?
                        <>
                            {/* eslint-disable-next-line max-len */}
                            Sie erfüllen alle Voraussetzungen für eine go-digital-Förderung. Gerne informieren wir Sie in einem persönlichen Gespräch über die Möglichkeiten <a href="https://1a-digital.com/go-digital/?chat=open">per Chat</a> oder hinterlassen Sie uns Ihre Kontaktdaten und wir melden uns bei Ihnen!
                        </> :
                        <>
                            {/* eslint-disable-next-line max-len */}
                            Leider erfüllen Sie nicht alle Voraussetzungen für eine go-digital-Förderung. Gerne beraten wir Sie jedoch auch zu anderen möglichen Förder- oder Umsetzungsmaßnahmen <a href="https://1a-digital.com/go-digital/?chat=open">per Chat</a> oder hinterlassen Sie uns Ihre Kontaktdaten und wir melden uns bei Ihnen!
                        </>
                }
            </p>

            <Form answers={answers} />
        </>
    ) : '';
}

Result.propTypes = {
    type: ProtTypes.bool,
    show: ProtTypes.bool,
    answers: ProtTypes.array,
};

export default Result;
