import React from 'react';
import PropTypes from 'prop-types';
import '../styles/headline.scss';

/**
 * Functional component to display the headlines
 * @prop {Object|String} children The headline
 * @return {Component} React component
 */
function Headline({children}) {
    return (
        <div>
            <div className="row text-center mb-4">
                <div className="d-table col-2 go-spacer">
                    <div className="d-table-cell sameHeight">
                        <hr className="border-dark"/>
                    </div>
                </div>
                <div className="headline col-8 d-table">
                    <div className="d-table-cell sameHeight">
                        {children}
                    </div>
                </div>
                <div className="d-table col-2 go-spacer">
                    <div className="d-table-cell sameHeight">
                        <hr className="border-dark"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

Headline.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string,
    ]),
};

export default Headline;
